import React from "react";
import "./caseStudiesPages.scss";
import NavProton from "../../shared/NavBar/NavProton";
import Container from "react-bootstrap/Container";
import ButtonBack from "../../shared/ButtonBack/ButtonBack";
import CaseStudyPage_1_img from "../../../../assets/images/CaseStidiesPages/case-study-page-1.png";
import CaseStudyPage_1_solution_img1 from "../../../../assets/images/CaseStidiesPages/case-study-page-1-solution-1.png";
import CaseStudyPage_1_solution_img2 from "../../../../assets/images/CaseStidiesPages/case-study-page-1-solution-2.png";
import CaseStudyPage_1_results_1 from "../../../../assets/images/CaseStidiesPages/case-study-page-1-results-1.png";
import Footer from "../../shared/Footer/Footer";

export default function CaseStudyPage_1() {
  return (
    <>
      <NavProton whiteBackground={true} selected="products" className="mb-5" />
      <ButtonBack redirectPath={"/salesforce"} />
      <div className="case-study-page-layout">
        <Container>
          <div className="row">
            <div className="col-8 header-info">
              <h1>
                Implementation of Service Cloud and Einstein Analytics in a
                growing retail company
              </h1>
              <ul>
                <li>Services offered: Service Cloud, Einstein Analytics</li>
                <li>Location: the Netherlands</li>
                <li>Industry: Retail industry</li>
                <li>Team composition: 9</li>
                <li>Time to implement: 2,5 month</li>
              </ul>
            </div>
            <div className="col-4">
              <img src={CaseStudyPage_1_img} className="header-img" />
            </div>
          </div>
          <div className="mt-1 pt-5">
            <h2>Points</h2>
            <ul>
              <li>
                Leveraged their existing Salesforce Service Cloud investment
              </li>
              <li>
                Implemented a custom Case Routing Round Robin algorithm in Apex
              </li>
              <li>
                Removed customer’s pain points and took advantage of the
                capabilities included with Salesforce Experience and Service
                cloud licenses.
              </li>
            </ul>
          </div>
          <div className="mt-3 pt-5">
            <h2>Problem</h2>
            <p>
              The client (retail industry) from the Netherlands needed to
              interact with their customers and improve customer engagement.
              They released a new product and faced exponential growth.Their old
              Case management solution was difficult and overwhelming, so they
              had to hire more and more staff in their Customer support team.
              Our client also needed a self-service solution to empower
              customers to find answers themselves - users would need to step
              through articles and notifications before being able to raise
              tickets.
            </p>
          </div>
          <div className="mt-3 pt-5">
            <h2>Our solution</h2>
            <ul>
              <li>
                Our team proposed a solution that leveraged their existing
                Salesforce Service Cloud investment.
              </li>
              <li>
                Our architecture team ensured that the solution removed
                customer’s pain points and took advantage of the capabilities
                included with Salesforce Experience and Service cloud licenses.
              </li>
              <li>
                Our engineering team delivered a cost-effective, self-service
                community with an overall reduction on caseloads.
              </li>
              <li>
                Our administrators configured Einstein Case Routing and Case
                Classification to get the right service agent faster for simple
                cases.
              </li>
              <li>
                For complex customer cases we have implemented a custom Case
                Routing Round Robin algorithm in Apex. The algorithm captures
                additional information from the third-party ETL system via SOAP
                requests and assigns cases to the right queues.
              </li>
              <li>
                Our Salesforce engineers implemented custom Lightning Web
                Components in order to aggregate useful information for service
                agents in a single screen.
              </li>
              <li>
                We also utilized Salesforce Open CTI to integrate the client's
                legacy telephony system natively to Salesforce.
              </li>
            </ul>
            <img
              src={CaseStudyPage_1_solution_img1}
              className="img-fluid py-4"
            ></img>
            <img
              src={CaseStudyPage_1_solution_img2}
              className="img-fluid pb-4"
            ></img>
            <p>
              The algorithm captures additional information from the third-party
              ETL system via SOAP requests and assigns cases to the right
              queues. Our Salesforce engineers implemented custom Lightning Web
              Components in order to aggregate useful information for service
              agents in a single screen. We also utilized Salesforce Open CTI to
              integrate the client's legacy telephony system natively to
              Salesforce.
            </p>
          </div>
          <div className="mt-3 py-5">
            <h2>Results </h2>
            <p>
              Our Salesforce team implemented a flexible CRM, built a sales
              funnel from the first contact of the client to the completion of
              the transaction. Now the client sees all the work of the
              department transparently, does not lose leads, uses only
              up-to-date information about leads and deals. The system is fully
              tailored to unique customer management workflows, including
              customer data collection, communication, automatic calculation of
              annuities and quotes, marketing and activity planning.
            </p>
            <p>
              This development helped to free up a temporary resource by 25%. In
              accordance with this, sales managers began to fulfill their KPIs
              by 140-160% on a monthly basis. It also turned out to aid
              marketing efforts thanks to the correct CRM, which sends e-mails
              automatically according to certain business processes. Thanks to
              the automation of business processes, customer enquiries were
              reduced and they began to choose self-service system in 7 out of
              10 cases.
            </p>
            <img
              src={CaseStudyPage_1_results_1}
              className="img-fluid pb-4"
            ></img>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}
