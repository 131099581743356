import React from "react";
import Container from "react-bootstrap/Container";
import ButtonBack from "../../shared/ButtonBack/ButtonBack";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./service.scss"

export default function Services({ }) {
    return (
        <>
            <ButtonBack redirectPath={"/products#salesforce"} />
            <div className="base--container page-layout white-page">
                <Container>
                    <h1>Services</h1>
                    <div className="row mt-3">
                        <div className="col col-lg-6 col-sm-12">
                            <Row>
                                <Col sm={12} lg="12">
                                    <div className="combined-shape"> </div>
                                    <h3 className="service-name">Consulting</h3>
                                </Col>
                                <Col sm={12} lg="12">
                                    <p className="service-text">Our team provides fundamental expertise gained through knowledge of best practices applied to your business (processes)</p>
                                </Col>
                            </Row>
                        </div>
                        <div className="col col-lg-6 col-sm-12">
                            <Row>
                                <Col sm={12} lg="12">
                                    <div className="combined-shape"> </div>
                                    <h3 className="service-name">Systems integration</h3>
                                </Col>
                                <Col sm={12} lg="12">
                                    <p className="service-text">Ensure that Salesforce solutions effectively work together and seamlessly connect with third-party apps</p>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col col-lg-6 col-sm-12">
                            <Row>
                                <Col sm={12} lg="12">
                                    <div className="combined-shape"> </div>
                                    <h3 className="service-name">Outsourcing</h3>
                                </Col>
                                <Col sm={12} lg="12">
                                    <p className="service-text">Based on a detailed business analysis, we build the best team working on your project, while ensuring transparency</p>
                                </Col>
                            </Row>
                        </div>
                        <div className="col col-lg-6 col-sm-12">
                            <Row>
                                <Col sm={12} lg="12">
                                    <div className="combined-shape"> </div>
                                    <h3 className="service-name">B2B Commerce</h3>
                                </Col>
                                <Col sm={12} lg="12">
                                    <p className="service-text">Tailor B2B commerce to fit the needs of your industry and drive your sales</p>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col col-lg-6 col-sm-12">
                            <Row>
                                <Col sm={12} lg="12">
                                    <div className="combined-shape"> </div>
                                    <h3 className="service-name">Custom development</h3>
                                </Col>
                                <Col sm={12} lg="12">
                                    <p className="service-text">Develop a custom solution with tailored dashboards, apps and workflows</p>
                                </Col>
                            </Row>
                        </div>
                        <div className="col col-lg-6 col-sm-12">
                            <Row>
                                <Col sm={12} lg="12">
                                    <div className="combined-shape"> </div>
                                    <h3 className="service-name">Salesforce migration</h3>
                                </Col>
                                <Col sm={12} lg="12">
                                    <p className="service-text">Migrate your data from your previous CRM to Salesforce with all historical data saved</p>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col col-lg-6 col-sm-12">
                            <Row>
                                <Col sm={12} lg="12">
                                    <div className="combined-shape"> </div>
                                    <h3 className="service-name">Support and maintenance</h3>
                                </Col>
                                <Col sm={12} lg="12">
                                    <p className="service-text">Resolve technical issues and get trainings and support from our Salesforce certified team</p>
                                </Col>
                            </Row>
                        </div>
                        <div className="col col-lg-6 col-sm-12">
                            <Row>
                                <Col sm={12} lg="12">
                                    <div className="combined-shape"> </div>
                                    <h3 className="service-name">Quickstarts</h3>
                                </Col>
                                <Col sm={12} lg="12">
                                    <p className="service-text">Great for implementation in independent departments and does not require much developer involvement and does not requite much development</p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
}
