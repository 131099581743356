import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./header.scss";
import "./header-animation.scss";
import Container from "react-bootstrap/Container";
import ProtonMap from "../../shared/Footer/Map";


export default function Header({ direction }) {
  return (
    <>
      <div className={`contact-header`}>
        <div className="header-text-container d-flex align-items-center">
          <Container>
            <Row>
              <Col sm={12} lg={5}>
                <Row>
                  <Col sm={12} lg={10}>
                    <p className="footer-company-name">
                      Proton R&D Innovations LTD
                    </p>
                    <p>P.O.Box 46617 - Abu Dhabi United Arab Emirates</p>
                  </Col>
                  <Col sm={12} lg={12}>
                    <p className="footer-email-info">
                      info@proton-innovations.com
                    </p>
                  </Col>

                </Row>
              </Col>
              <Col sm={12} lg={7}>
                <ProtonMap />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
