import React from "react";
import "./caseStudiesPages.scss";
import NavProton from "../../shared/NavBar/NavProton";
import ButtonBack from "../../shared/ButtonBack/ButtonBack";
import Footer from "../../Products/Footer/Footer";
import Container from "react-bootstrap/Container";
import CaseStudyPage_3_img from "../../../../assets/images/CaseStidiesPages/case-study-page-3.png";
import CaseStudyPage_3_solution_img1 from "../../../../assets/images/CaseStidiesPages/case-study-page-3-solution-1.png";
import CaseStudyPage_3_solution_img2 from "../../../../assets/images/CaseStidiesPages/case-study-page-3-solution-2.png";
import CaseStudyPage_3_results_1 from "../../../../assets/images/CaseStidiesPages/case-study-page-3-results-1.png";

export default function CaseStudyPage_1() {
  return (
    <>
      <NavProton whiteBackground={true} selected="products" className="mb-5" />
      <ButtonBack redirectPath={"/salesforce"} />
      <div className="case-study-page-layout">
        <Container>
          <div className="row">
            <div className="col-8 header-info">
              <h1>
                Implementation of Sales cloud for processing applications and
                mailing price lists
              </h1>
              <ul>
                <li>Services offered: Sales cloud</li>
                <li>Location: USA</li>
                <li>Industry: Logistics</li>
                <li>
                  Team composition: 6 people (project manager, business analyst,
                  system architect, 3 developers)
                </li>
                <li>Time to implement: 2 month</li>
              </ul>
            </div>
            <div className="col-4">
              <img
                src={CaseStudyPage_3_img}
                atl="trucks photo"
                className="header-img"
              />
            </div>
          </div>
          <div className="mt-3 pt-5">
            <h2>Problem</h2>
            <p>
              The problem is the lack of a centralized system for the supply of
              up-to-date price lists with the necessary positions for each
              specific supplier. The human factor influenced the response time
              of managers, the relevance of information. In addition, it is
              difficult for managers to analyze, plan and manage a large
              database.
            </p>
          </div>
          <div className="mt-3 pt-5">
            <h2>Our solution</h2>
            <ul>
              <li>
                At the first stage, a business analyst works with the project.
                He studies in detail existing processes and needs, and also
                suggests ways to automate them.
              </li>
              <li>
                A business analyst draws automated business processes. The way
                they will function, with the possibility of system growth and
                expansion.
              </li>
              <img
                src={CaseStudyPage_3_solution_img1}
                className="img-fluid py-4"
              ></img>
              <li>
                Formation of technical specifications for development and
                coordination with the client.
              </li>
              <li>
                Coordination of the development team with the customer, drawing
                up a contract with a clear indication of the hours of work, the
                cost of the project.
              </li>
              <img
                src={CaseStudyPage_3_solution_img2}
                className="img-fluid  pb-4"
              ></img>
              <li>Approval with the client, signing the contract.</li>
              <li>Technical implementation, testing and troubleshooting.</li>
              <li>Delivery of a turnkey solution and demonstration.</li>
              <li>
                Upon completion of the work, we conduct basic training for key
                users and subsequently provide technical support and
                maintenance.
              </li>
            </ul>
          </div>
          <div className="mt-3 py-5">
            <h2>Results </h2>
            <p>
              Implemented a system based on Salesforce, which employs a team of
              10 people. The throughput of the system is more than 60,000
              positions. The system now allows to process requests from
              suppliers and send a price list with specific items for each buyer
              by mail to each specific buyer. Thus, managers have freed up time
              resources and their efficiency has increased by more than 40%.
            </p>
            <img
              src={CaseStudyPage_3_results_1}
              className="img-fluid pb-4"
            ></img>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}
