import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

import "./howWeCanHelp.scss";

export default function HowWeCanHelp() {
  const tabContent = [
    {
      id: "automation",
      title: "Automation of business processes",
      text: "Every company has its own set of business processes, and the better they are set up, the higher the profitability of the company. We automate business processes with Salesforce. Salesforce is not just a CRM, but a whole ecosystem with artificial intelligence, suitable for any kind of business. You can automate an entire company, as well as departments or specific functions.",
      bulletPoints: [
        "Who wants to maximize efficiency and profitability",
        "Who wants to speed up processes and stages of their company's work",
        "Who does not know how to organize their work with clients in the most profitable and comfortable way",
        "Who wants to free up time resources",
        "Who wants to decrease influence of the human factor and the number of errors related to it",
        "Who uses various tools, but does not see the result from the",
      ],
    },
    {
      id: "optimization",
      title: "Salesforce optimization",
      text: "Our team of certified experts will make an audit of your Salesforce CRM and scale it to make it more convenient and efficient to use, despite the number of users and clients.",
      bulletPoints: [
        "For those, who already use Salesforce and wants to optimize its performance",
        "For those who don't use all the features of Salesforce or don't know how",
        "Who wants to leverage all Salesforce features to their benefit",
        "Who needs to customize user interface",
      ],
    },
    {
      id: "customization",
      title: "Salesforce customization",
      text: "Salesforce can be tailored to meet your business needs in two ways - through configuration and customization. With configuration, the CRM functionality is configured through the Salesforcе admin user interface without the use of code. Customization is required if the configuration options are insufficient to automate complex business logic. Customization allows you to get the most individualized system.",
      bulletPoints: [
        "Who wants to develop new functionalities for standard business modules",
        "Who wants to develop custom applications for Salesforce",
        "Who wants to create custom reports, dashboards and workflows",
        "Who wants to integrate Salesforce with other systems (e.g., ERP, company website)",
        "Develop custom templates for email",
      ],
    },
  ];

  const getNavLink = (tabContent, index) => {
    return (
      <Nav.Item key={index}>
        <Nav.Link eventKey={tabContent.id}>{tabContent.title}</Nav.Link>
      </Nav.Item>
    );
  };

  const getTabContent = (tabContent, index) => {
    return (
      <Tab.Pane eventKey={tabContent.id} key={index}>
        <h3>{tabContent.title}</h3>
        <p>{tabContent.text}</p>
        <p>Who is it right for?</p>
        <ul>
          {tabContent.bulletPoints.map((bulletPoint, index) => {
            return <li key={index}>{bulletPoint}</li>;
          })}
        </ul>
      </Tab.Pane>
    );
  };
  return (
    <div className="section-container page-layout white-page">
      <Container>
        <div className="row">
          <div className="col-sm-12 col-lg-7">
            <h2>How we can help you?</h2>
          </div>
        </div>
        <Tab.Container defaultActiveKey="automation">
          <Row>
            <Col sm={12} lg={3}>
              <Nav
                variant="pills"
                className="flex-row flex-lg-column justify-content-sm-around"
              >
                {tabContent.map((item, index) => {
                  return getNavLink(item, index);
                })}
              </Nav>
            </Col>
            <Col lg={1}></Col>
            <Col sm={12} lg={8}>
              <Tab.Content>
                {tabContent.map((item, index) => {
                  return getTabContent(item, index);
                })}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </div>
  );
}
