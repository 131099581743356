import React, { useState } from "react";
import "./footer.scss";
import "./footer-animation.scss";
import "../../../styles/shared.scss";
import "../../../styles/shared/page-animations.scss";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import ContactForm from "../ContactForm/ContactForm";
import ProtonMap from "./Map";
import { menuData } from "../NavBar/navBarData";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

function Footer({ lang }) {
  let history = useHistory();

  const [en] = useState({
    terms: "TERMS OF SERVICES",
    privacy: "PRIVACY POLICY",
    cookies: "COOKIES",
  });
  const [ar] = useState({
    terms: "شروط الخدمة",
    privacy: "سياسة الخصوصية",
    cookies: "ملفات تعريف الارتباط",
  });

  return (
    <>
      <div className="footer-layout d-flex align-items-center">
        <Container>
          <Row>
            <Col sm={0} lg={2} className="hide-on-mobile">
              <ul>
                {menuData.map((nav, index) => (
                  <li key={index}>
                    <a href="#" onClick={() => history.push(nav.path)}>
                      {nav.label}
                    </a>
                  </li>
                ))}
              </ul>
            </Col>
            <Col sm={0} lg={3} className="hide-on-mobile">
              <ul>
                <li>
                  <a href="#">{lang === "EN" ? en.terms : ar.terms}</a>
                </li>
                <li>
                  <a href="#">{lang === "EN" ? en.privacy : ar.privacy}</a>
                </li>
                <li>
                  <a href="#">{lang === "EN" ? en.cookies : ar.cookies}</a>
                </li>
              </ul>
            </Col>
            <Col sm={0} lg={1} className="hide-on-mobile">
              {" "}
            </Col>
            <Col sm={12} lg={6}>
              <ContactForm />
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col sm={12} lg={5}>
              <Row>
                <Col lg={10}>
                  <p className="footer-company-name">
                    Proton R&D Innovations LTD
                  </p>
                  <p>P.O.Box 46617 - Abu Dhabi United Arab Emirates</p>
                </Col>

                <Col lg={12}>
                  <p className="footer-email-info">
                    info@proton-innovations.com
                  </p>
                </Col>

              </Row>
            </Col>
            <Col sm={12} lg={7}>
              <ProtonMap />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(Footer);
