// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/cedtified-partner-section-background.png");
exports = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".certified-partner-section{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");height:27vh}@media only screen and (max-device-width: 767px){.certified-partner-section .row{height:100%;margin:0;justify-content:center}.certified-partner-section img{max-width:20%;padding-bottom:5% !important}}", "",{"version":3,"sources":["/home/worker/proton_innovations_web/app/javascript/components/EasyProject/CertifiedPartner/CertifiedPartner.scss"],"names":[],"mappings":"AAAA,2BACE,wDAAA,CACA,WAAA,CAGF,iDAEI,gCACE,WAAA,CACA,QAAA,CACA,sBAAA,CAEF,+BACE,aAAA,CACA,4BAAA,CAAA","file":"CertifiedPartner.scss","sourcesContent":[".certified-partner-section {\n  background-image: url(\"../../../../assets/images/cedtified-partner-section-background.png\");\n  height: 27vh;\n}\n\n@media only screen and (max-device-width: 767px) {\n  .certified-partner-section {\n    .row {\n      height: 100%;\n      margin: 0;\n      justify-content: center;\n    }\n    img {\n      max-width: 20%;\n      padding-bottom: 5% !important;\n    }\n  }\n}\n"]}]);
// Exports
module.exports = exports;
