import "../../styles/index.scss";
import React, { useState, useEffect } from 'react';
import ShowCaseProton from './Main/Main'
import AboutUs from './AboutUs/AboutUs'
import EasyProject from './EasyProject/EasyProject'
import Tesey from './Tesey/Tesey'
import Testimonials from './Testimonials/Testimonials'
import LandingFooter from './Footer/Footer'
import './landing.scss'
import PageDivider from "../shared/PageDivider";

export default function Index() {
    const [pageIndex, setPageIndex] = useState(0);
    const [direction, setDirection] = useState('none');
    const [waitingAnimation, setWaitingAnimation] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setWaitingAnimation(false)
        }, 3500);
    }, []);

    return (
        <>
            <div className="body-container landing-page-main-container">
                <ShowCaseProton   display={ pageIndex === 0 } direction={ getDirection(0) }/>
                <AboutUs          />
                <EasyProject      />
                <PageDivider direction={"left"}/>
                <Tesey            />
                <Testimonials     />
                <LandingFooter    />
            </div>
        </>
    );

    function getDirection(id) {
        if (pageIndex === id) {
            return direction
        } else if (pageIndex > id) {
            return 'right-hide'
        } else {
            return 'left-hide'
        }
    }

    function wheelToPage(e) {
        setWaitingAnimation(true)
        let currentPage

        if (e.deltaY < 0) {
            setDirection('left')
            currentPage = pageIndex - 1
            setPageIndex(pageIndex === 0 ? 0 : currentPage)
        } else if (e.deltaY > 0) {
            setDirection('right')
            currentPage = pageIndex + 1
            setPageIndex(pageIndex === 5 ? 5 : currentPage)
        }

        setTimeout(() => {
            setWaitingAnimation(false)
        }, currentPage === 0 ? 3500 : 1000);
    }
}