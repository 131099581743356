import React from "react";
import Container from "react-bootstrap/Container";

import ProjectManagerIcon from "../../../../assets/images/ManagementIcons/Project Manager.png";
import AgileIcon from "../../../../assets/images/ManagementIcons/Agile.png";
import ResumeIcon from "../../../../assets/images/ManagementIcons/Resume.png";
import WorkflowIcon from "../../../../assets/images/ManagementIcons/Workflow.png";
import TemplateIcon from "../../../../assets/images/ManagementIcons/Template.png";
import Row from "react-bootstrap/Row";
import ProtonButton from "../../shared/ProtonButton";

export default function StageOfWork({}) {
  return (
    <>
      <div className="base--container page-layout white-page">
        <Container>
          <h2>Stages of work</h2>
          <div className="row mt-5 justify-content-md-center">
            <div className="col-sm-12 col-lg-2">
              <div className="col">
                <div className="card">
                  <img
                    src={ProjectManagerIcon}
                    className="card-img-top img-fluid"
                    alt={` image`}
                  />
                  <div className="card-body">
                    <h5 className="card-title">Getting to know</h5>
                    <p className="card-text">
                      Establishing a connection with the client
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-2">
              <div className="col">
                <div className="card">
                  <img
                    src={ResumeIcon}
                    className="card-img-top img-fluid"
                    alt={` image`}
                  />
                  <div className="card-body">
                    <h5 className="card-title">Research</h5>
                    <p className="card-text">
                      Identification of customer requirements. You can tell just
                      the gist of an issue or idea, and our team will find the
                      best solution
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-2">
              <div className="col">
                <div className="card">
                  <img
                    src={WorkflowIcon}
                    className="card-img-top img-fluid"
                    alt={` image`}
                  />
                  <div className="card-body">
                    <h5 className="card-title">Preparation</h5>
                    <p className="card-text">
                      Analyze the plan for feasibility, create a solution plan,
                      and allocate resources and time
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-2">
              <div className="col">
                <div className="card">
                  <img
                    src={AgileIcon}
                    className="card-img-top img-fluid"
                    alt={` image`}
                  />
                  <div className="card-body">
                    <h5 className="card-title">Building & Testing</h5>
                    <p className="card-text">
                      Approval, execution of the technical part, exporting and
                      importing data. Testing and security validation
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-2">
              <div className="col">
                <div className="card">
                  <img
                    src={TemplateIcon}
                    className="card-img-top img-fluid"
                    alt={` image`}
                  />
                  <div className="card-body">
                    <h5 className="card-title">Service</h5>
                    <p className="card-text">
                      Technical support and training upon completion of services
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Row className="justify-content-md-around align-items-md-center w-100 pt-3">
            <ProtonButton label="Let's talk" redirectPath="/contact?salesForce=true" />
          </Row>
        </Container>
      </div>
    </>
  );
}
