import React from "react";
import Container from "react-bootstrap/Container";
import Trolley from "../../../../assets/images/trolley.png";
import ToyHouse from "../../../../assets/images/toy-house-photo.png";
import Trucks from "../../../../assets/images/trucks.png";
import BusinessMeeting from "../../../../assets/images/businesspeople-meeting.png";
import OpenBook from "../../../../assets/images/opened-book.png";

import "./caseStudies.scss";

export default function CaseStudies() {
  return (
    <div className="case-studies-section-container page-layout white-page">
      <Container>
        <div className="row">
          <div className="col-sm-12 col-lg-8 pb-5">
            <h2>Case studies</h2>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 p-0">
            <img src={Trolley} atl="trolley photo" className="img-fluid" />
          </div>
          <div className="col-12 col-lg-6">
            <a href="/salesforce/case_studies_1">
              Implementation of Service Cloud and Einstein Analytics in a
              growing retail company
            </a>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-12 col-lg-4 flex-column p-0">
            <img src={ToyHouse} atl="toy house photo" />
            <div className="px-lg-5 py-sm-5 px-sm-3 py-lg-0 text-left">
              <a href="/salesforce/case_studies_2">Data orchestration</a>
            </div>
          </div>
          <div className="col-12 col-lg-4 flex-column p-0">
            <img src={Trucks} atl="trucks photo" />
            <div className="px-lg-5 pb-sm-5 px-sm-3 py-lg-0 text-left">
              <a href="/salesforce/case_studies_3">
                Implementation of Sales cloud for processing applications and
                mailing price lists
              </a>
            </div>
          </div>
          <div className="col-12 col-lg-4 flex-column p-0">
            <img src={BusinessMeeting} atl="business meeting photo" />
            <div className="px-sm-3 pb-sm-5 px-lg-4 py-lg-0 text-left">
              <a href="/salesforce/case_studies_4">
                Secure Integration with Sales Cloud, Service Cloud & Experience
                Cloud for Financial Institution
              </a>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 p-0">
            <img src={OpenBook} atl="open book photo" />
          </div>
          <div className="col-12 col-lg-6">
            <a href="/salesforce/case_studies_5">
              Adapting and developing a new data and flow model using Zuora,
              Salesforce Sales Cloud & Experience Cloud solutions
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
}
