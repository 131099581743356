import React from "react";
import "./caseStudiesPages.scss";
import NavProton from "../../shared/NavBar/NavProton";
import ButtonBack from "../../shared/ButtonBack/ButtonBack";
import Footer from "../../Products/Footer/Footer";
import Container from "react-bootstrap/Container";
import CaseStudyPage_4_img from "../../../../assets/images/CaseStidiesPages/case-study-page-4.png";
import CaseStudyPage_4_solution_img1 from "../../../../assets/images/CaseStidiesPages/case-study-page-4-solution-1.png";
import CaseStudyPage_4_solution_img2 from "../../../../assets/images/CaseStidiesPages/case-study-page-4-solution-2.png";

export default function CaseStudyPage_1() {
  return (
    <>
      <NavProton whiteBackground={true} selected="products" className="mb-5" />
      <ButtonBack redirectPath={"/salesforce"} />
      <div className="case-study-page-layout">
        <Container>
          <div className="row">
            <div className="col-8 header-info">
              <h1>
                Secure Integration with Sales Cloud, Service Cloud & Experience
                Cloud for Financial Institution
              </h1>
              <ul>
                <li>
                  Services offered: Sales Cloud, Service Cloud & Experience
                  Cloud
                </li>
                <li>Location: Finland</li>
                <li>Industry: Fintech</li>
                <li>Team composition: 6</li>
                <li>Time to implement: 1 month</li>
              </ul>
            </div>
            <div className="col-4">
              <img
                src={CaseStudyPage_4_img}
                atl="business meeting photo"
                className="header-img"
              />
            </div>
          </div>
          <div className="mt-3 pt-5">
            <h2>Problem</h2>
            <p>
              The Finnish client (a fintech company) needed to improve their
              integration (security and performance) with third-party Bank
              systems. They also wanted their Lead data to be useful. Their
              Leads weren’t organized, prioritized and tracked properly.
            </p>
          </div>
          <div className="mt-3 pt-5">
            <h2>Our solution</h2>
            <ul>
              <li>
                Our team engineering team focused on securing integration
                channels between customer’s ETL systems, Salesforce and
                third-party Bank systems.
              </li>
              <img
                src={CaseStudyPage_4_solution_img1}
                className="img-fluid py-4"
              ></img>
              <li>
                Our architecture team designed event-driven integration
                architecture and created mechanisms to ensure fault-tolerant
                financial data exchange.
              </li>
              <li>
                We have implemented custom Apex code in order to enrich Lead
                data with additional details from third-party systems. Clear
                matching algorithms matching algorithms were implemented to sort
                and route Leads depending on their priority and origin.
                Deduplication techniques were integrated in order to eliminate
                redundant routine efforts from Sales and Support teams.
              </li>
              <img
                src={CaseStudyPage_4_solution_img2}
                className="img-fluid  pb-4"
              ></img>
            </ul>
          </div>
          <div className="mt-3 py-5">
            <h2>Results </h2>
            <p>
              As a result of the organization of leads, the company has stopped
              losing customers, now the information from all channels is stored
              in one system. All customer requests are stored in one place. This
              allows to process leads and offer them relevant services when they
              need them the most. When a customer sends a request the
              transaction is formed automatically with reference to the
              responsible employees, if necessary, you can enable the recording
              of phone calls and listen to them directly in the CRM through
              configured IP-telephony.
            </p>
            <p>
              The sales and production processes are also optimized. As the deal
              passes through the funnels, the necessary employees are
              automatically assigned tasks, and they see at what stage the deal
              is at all times. It's extremely important to minimize routine
              activities of employees, so they can spend time on more important
              things. For this purpose, we have implemented an auto-generation
              of sealed and signed commercial offer in PDF.
            </p>
            <p>
              We have successfully integrated 5 payment systems, which are fully
              compliant with security and absolutely reliable. Payment systems
              have been implemented into the website and synchronized with the
              system in order to provide managers with the most up-to-date
              information on transactions.
            </p>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}
