import React, { useEffect, useState } from "react";
import NavProton from "../shared/NavBar/NavProton";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import ContactUs from "./ContactUs/ContactUs";
import "./contacts.scss";
import { connect } from "react-redux";

function Company({ lang }) {
  // useEffect(() => {
  //   let h1 = [].slice.call(document.getElementsByTagName("h1"));
  //   let map = document.querySelector(".company-google-map");
  //   if (lang === "AR") {
  //     document.body.style.direction = "rtl";
  //     h1.forEach((element) => (element.style.textAlign = "center"));
  //     map.style.left = "300px";
  //   } else {
  //     document.body.style.direction = "ltr";
  //     map.style.left = "0px";
  //   }
  // });

  return (
    <div>
      <div
        className={`proton-contact-page `}>
        <NavProton selected="contact" whiteBackground={true} />
        <ContactUs />
        <Header />
        {/*<Footer />*/}
      </div>
    </div>
  );

  function getDirection(id) {
    if (pageIndex === id) {
      return direction;
    } else if (pageIndex > id) {
      return "right-hide";
    } else {
      return "left-hide";
    }
  }

  function sidebarClass() {
    if (pageIndex === 0) {
      return "";
    } else {
      return "navbar-hidden";
    }
  }

  function wheelToPage(e) {
    setWaitingAnimation(true);
    let currentPage;

    if (e.deltaY < 0) {
      setDirection("left");
      currentPage = pageIndex - 1;
      setPageIndex(pageIndex === 0 ? 0 : currentPage);
    } else if (e.deltaY > 0) {
      setDirection("right");
      currentPage = pageIndex + 1;
      setPageIndex(pageIndex === 2 ? 2 : currentPage);
    }

    setTimeout(() => {
      setWaitingAnimation(false);
    }, 1000);
  }
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(Company);
