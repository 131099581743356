import React from "react";
import SharedReadyToStart from '../../shared/ReadyToStart/ReadyToStart'


export default function ReadyToStart({  }) {
    return (
        <>
            <div className={`product-ready-to-start`}>
                <SharedReadyToStart />
            </div>
        </>
    );
}