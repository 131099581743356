import React from "react";
import Landing from "./landing/Landing";
import Company from "./Company/Company";
import Products from "./Products/Products";
import Rnd from "./Rnd/Rnd";
import Services from "./Services/Services";
import Contacts from "./Contacts/Contacts";
import EasyProject from "./EasyProject/EasyProject";
import Salesforce from "./SalesForce/Salesforce";
import CaseStudyPage_1 from "./SalesForce/CaseStudiesPages/CaseStudyPage_1";
import CaseStudyPage_2 from "./SalesForce/CaseStudiesPages/CaseStudyPage_2";
import CaseStudyPage_3 from "./SalesForce/CaseStudiesPages/CaseStudyPage_3";
import CaseStudyPage_4 from "./SalesForce/CaseStudiesPages/CaseStudyPage_4";
import CaseStudyPage_5 from "./SalesForce/CaseStudiesPages/CaseStudyPage_5";
import EasyProjectPricing from "./EasyProject/PricingPage/PricingPage";
import Tesey from "./Tesey/Tesey";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import langReducer from "../reducers";
import thunk from "redux-thunk";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = createStore(langReducer, composeEnhancers(applyMiddleware(thunk)));

function App() {
  return (
    <Switch>
      <Route path="/company">
        <Company />
      </Route>
      <Route path="/products">
        <Products />
      </Route>
      <Route path="/rnd">
        <Rnd />
      </Route>
      <Route path="/services">
        <Services />
      </Route>
      <Route path="/contact">
        <Contacts />
      </Route>
      <Route path="/easy_project/pricing">
        <EasyProjectPricing />
      </Route>
      <Route path="/easy_project">
        <EasyProject />
      </Route>
      <Route path="/salesforce/case_studies_1">
        <CaseStudyPage_1 />
      </Route>
      <Route path="/salesforce/case_studies_2">
        <CaseStudyPage_2 />
      </Route>
      <Route path="/salesforce/case_studies_3">
        <CaseStudyPage_3 />
      </Route>
      <Route path="/salesforce/case_studies_4">
        <CaseStudyPage_4 />
      </Route>
      <Route path="/salesforce/case_studies_5">
        <CaseStudyPage_5 />
      </Route>
      <Route path="/salesforce">
        <Salesforce />
      </Route>
      <Route path="/tesey">
        <Tesey />
      </Route>
      <Route path="/">
        <Landing />
      </Route>
    </Switch>
  );
}

export default function Index() {
  return (
    <>
      <Provider store={store}>
        <Router forceRefresh={true}>
          <App />
        </Router>
      </Provider>
    </>
  );
}
