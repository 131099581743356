import React, {useEffect, useState} from "react";
import NavProton from "../shared/NavBar/NavProton";
import './tesey.scss'
import Header from './Header/Header'
import Tools from './Tools/Tools'
import Features from './Features/Features'
import ReadyToStart from './ReadyToStart/ReadyToStart'
import EpFooter from './Footer/Footer'

import '../../styles/shared.scss'

export default function Tesey() {
    return (
        <>
            <div className={`proton-services-page `}>
                <NavProton whiteBackground={true} selected="products"/>

                <Header />
                <Tools />
                <Features />
                <ReadyToStart />
                <EpFooter />
            </div>
        </>
    );
}