import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./header.scss";
import "./header-animation.scss";
import "./header-animation-reversed.scss";
import Container from "react-bootstrap/Container";
import image from "../../../../assets/images/image - company.jpg";
import { connect } from "react-redux";

function Header({ direction, lang }) {
  const [en] = useState({
    heading:
      "Proton’s team is made up of professionals that stand at the cutting edge of technology.",
    subHeading:
      "With over 20 years of combined experience the team has developed user-friendly and powerful cybersecurity and project management solutions that are used by companies around the globe.",
  });
  const [ar] = useState({
    heading: "يتكون فريق بروتون من محترفين يقفون في طليعة التقدم التكنولوجي",
    subHeading:
      "مع أكثر من ٢٠ عاما من الخبرة المدمجة وقد طور الفريق حلولا قويه و سهلة الاستخدام للأمن السيبراني و إدارة المشاريع التي تستخدمها الشركات في جميع أنحاء العالم",
  });
  useEffect(() => {
    let p = [].slice.call(document.getElementsByTagName("p"));
    let h1 = [].slice.call(document.getElementsByTagName("h1"));
    let h3 = [].slice.call(document.getElementsByTagName("h3"));
    if (lang === "AR") {
      document.body.style.direction = "rtl";
      h1.forEach((element) => (element.style.textAlign = "right"));
      p.forEach((element) => (element.style.textAlign = "right"));
      h3.forEach((element) => (element.style.textAlign = "right"));
    } else {
      document.body.style.direction = "ltr";
      h1.forEach((element) => (element.style.textAlign = "left"));
      p.forEach((element) => (element.style.textAlign = "left"));
      h3.forEach((element) => (element.style.textAlign = "left"));
    }
  });

  return (
    <>
      <div
        className={`company-header-container company-header-scroll-${direction}${
          lang === "AR" ? "-reversed" : ""
        }`}>
        <div className="company-header-layout d-flex align-items-center">
          <div className="company-header-image-container d-flex">
            <img
              src={image}
              className="company-header-image"
              alt="RND Proton It Security consulting"
            />
          </div>

          <Container>
            <Row>
              <Col sm={{ span: 12, offset: 0 }} lg={{ span: 10, offset: 3 }}>
                <h1 className="company-header-text">
                  {lang === "EN" ? en.heading : ar.heading}
                </h1>
              </Col>
            </Row>
            <Row>
              <Col sm={{ span: 12, offset: 0 }} lg={{ span: 9, offset: 3 }}>
                <p className="company-header-subtitle">
                  {lang === "EN" ? en.subHeading : ar.subHeading}
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(Header);
