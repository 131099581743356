import React from "react";
import Container from "react-bootstrap/Container";
import image from "../../../../assets/images/SF-Our-Team.png";

export default function Services({ }) {
    return (
        <>
            <div className="base--container page-layout white-page">
                <Container>
                    <div className="row mt-5">
                        <div className="col-sm-12 col-lg-7">
                            <h1>Our team</h1>
                            <p className="service-text">We are a Salesforce full-cycle consultancy and a Certified Salesforce Partner, specializing in providing high quality expertise and development of custom solutions for Salesforce ecosystem</p>
                            <div className="combined-shape"> </div>
                            <h3 className="service-name">26 Salesforce experts</h3>
                            <div className="combined-shape"> </div>
                            <h3 className="service-name">7+ Years in operation</h3>
                            <div className="combined-shape"> </div>
                            <h3 className="service-name">73% Salesforce Middle/Senior level</h3>
                            <div className="combined-shape"> </div>
                            <h3 className="service-name">28 Average age of the company</h3>
                            <div className="combined-shape"> </div>
                            <h3 className="service-name">95+ Successful projects</h3>
                        </div>
                        <div className="col-sm-12 col-lg-5">
                            <img
                                className="video-preview-img img-fluid"
                                src={image}
                                art="Our Salesforce team"
                            />
                        </div>
                    </div>

                </Container>
            </div>
        </>
    );
}