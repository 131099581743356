import React, { useState } from "react";
import './tesey.scss'
import './tesey-animation.scss'
import SharedTesey from '../../shared/Tesey/Tesey'

export default function Tesey({direction}) {
    return (
        <>
            <div className={ `product-tesey ` }>
                <SharedTesey />
            </div>
        </>
    );
}