import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProtonButton from "../../shared/ProtonButton";
import image from "../../../../assets/images/salesforce-products.png";

export default function Salesforce({}) {
  return (
    <>
      <div className="product-easy-project" id="salesforce">
        <div className={`easy-project-page`}>
          <div className="easy-project-layout d-flex align-items-center">
                            <Container>
              <Row className="align-items-center ">
                <Col sm={12} lg={7}>
                  <Row>
                    <Col sm={12} lg="10">
                      <h1 className={"ep-heading"}>Salesforce</h1>
                      <p>
                        Our team of certified Salesforce consultants, developers
                        and admins is ready to solve any task you have in mind,
                        be it migrating to Salesforce, CRM customization and
                        optimizing CRM performance, or your whole business
                                  ocess automation
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12} lg={4}>
                  <div className="video-preview-container">
                    <img
                      className="video-preview-img img-fluid"
                      src={image}
                      art="Laptop with Easy Project"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-md-around align-items-md-center w-100 pt-3">
                <div className="page-more-button">
                  <ProtonButton label="LET'S TALK" redirectPath="/contact#" />
                </div>
                <div className="page-more-button">
                  <a className="proton-button" href="/salesforce">
                    LEARN MORE
                  </a>
                </div>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
