import React, { useState, useEffect } from "react";
import "./contact-form.scss";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";

function ContactForm({ oneColumn, useTopic, lang }) {
  useEffect(() => {
    if (location.href.includes("salesForce")) {
      setTopic("Salesforce consulting")
    }
  }, []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [topic, setTopic] = useState("");
  const [topicOptionsEn] = useState([
    "Select topic",
    "Easy Project",
    "Salesforce consulting",
    "Software development",
    "Cloud hosting",
    "Other",
  ]);
  const [topicOptionsAr] = useState([
    "اختر الموضوع",
    "مشروع سهل",
    "تيسي",
    "تطوير التطبيقات",
    "استشارات",
    "آخر",
  ]);
  const [en] = useState({
    name: "*Name",
    email: "*Email",
    message: "*Message",
    send: "SEND",
  });
  const [ar] = useState({
    name: "اسم*",
    email: "عنوان بريد الكتروني*",
    message: "الرسالة*",
    send: "إرسال",
  });
  const [messageSent, setMessageSent] = useState(false);

  const handleNameChange = () => (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = () => (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = () => (e) => {
    setMessage(e.target.value);
  };

  const handleTopicChange = () => (e) => {
    setTopic(e.target.value);
  };

  const handleSubmit = () => (e) => {
    fetch("/contact", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        form: {
          source: useTopic ? "contact-us" : "footer",
          topic: topic,
          name: name,
          email: email,
          message: message
        },
      }),
    }).then(function (response) {
      setMessageSent(true);
    });
  };

  const renderOptions = () => {
    if (lang === "EN") {
      return topicOptionsEn.map((option, index) => (
        <option key={index}>{option}</option>
      ));
    } else {
      return topicOptionsAr.map((option, index) => (
        <option key={index}>{option}</option>
      ));
    }
  };

  if (messageSent) {
    return (
        <div className="proton-contact-form">
          <p className={"thank-you-message"}>Thank you!</p>
        </div>
    );
  } else {
    return (
        <>
          <div className="proton-contact-form">
            <Form onSubmit={handleSubmit}>
              <Row>
                {useTopic && (
                    <Col sm={12} lg={12}>
                      <Form.Control
                          placeholder="Select topic"
                          value={topic}
                          onChange={handleTopicChange()}
                          as="select">
                        {renderOptions()}
                      </Form.Control>
                    </Col>
                )}
                <Col sm={12} lg={oneColumn ? 12 : 6}>
                  <Form.Control
                      placeholder={lang === "EN" ? en.name : ar.name}
                      value={name}
                      onChange={handleNameChange()}
                  />
                </Col>
                <Col sm={12} lg={oneColumn ? 12 : 6}>
                  <Form.Control
                      placeholder={lang === "EN" ? en.email : ar.email}
                      value={email}
                      onChange={handleEmailChange()}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                      as="textarea"
                      rows={6}
                      placeholder={lang === "EN" ? en.message : ar.message}
                      className="message-area"
                      value={message}
                      onChange={handleMessageChange()}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-end">
                  <a
                      className="send-form-button"
                      type="button"
                      onClick={handleSubmit()}>
                    {lang === "EN" ? en.send : ar.send}
                  </a>
                </Col>
              </Row>
            </Form>
          </div>
        </>
    );
  }
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(ContactForm);
