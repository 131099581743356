import React from "react";
import "./caseStudiesPages.scss";
import NavProton from "../../shared/NavBar/NavProton";
import ButtonBack from "../../shared/ButtonBack/ButtonBack";
import Container from "react-bootstrap/Container";
import Footer from "../../Products/Footer/Footer";
import CaseStudyPage_5_img from "../../../../assets/images/CaseStidiesPages/case-study-page-5.png";
import CaseStudyPage_5_solution_img1 from "../../../../assets/images/CaseStidiesPages/case-study-page-5-solution-1.png";
import CaseStudyPage_5_solution_img2 from "../../../../assets/images/CaseStidiesPages/case-study-page-5-solution-2.png";
import CaseStudyPage_5_solution_img3 from "../../../../assets/images/CaseStidiesPages/case-study-page-5-solution-3.png";
import CaseStudyPage_5_solution_img4 from "../../../../assets/images/CaseStidiesPages/case-study-page-5-solution-4.png";
import CaseStudyPage_5_solution_img5 from "../../../../assets/images/CaseStidiesPages/case-study-page-5-solution-5.png";

export default function CaseStudyPage_1() {
  return (
    <>
      <NavProton whiteBackground={true} selected="products" className="mb-5" />
      <ButtonBack redirectPath={"/salesforce"} />
      <div className="case-study-page-layout">
        <Container>
          <div className="row">
            <div className="col-8 header-info">
              <h1>
                Adapting and developing a new data and flow model using Zuora,
                Salesforce Sales Cloud & Experience Cloud solutions
              </h1>
              <ul>
                <li>
                  Services offered: Zuora, Salesforce Sales Cloud & Experience
                  Cloud
                </li>
                <li>Location: United States</li>
                <li>Industry: Publishing</li>
                <li>Team composition: 7</li>
                <li>Time to implement: 2 month</li>
              </ul>
            </div>
            <div className="col-4">
              <img
                src={CaseStudyPage_5_img}
                atl="open book photo"
                className="header-img"
              />
            </div>
          </div>
          <div className="mt-3 pt-5">
            <h2>Problem</h2>
            <p>
              A client in the US (publishing industry) asked us to adapt their
              magazine subscription process to new market requirements. They
              needed to launch a digital subscription revenue system. Prior to
              implementation, they were using legacy systems that lacked the
              flexibility and agility they needed to sell magazine subscriptions
              to a wider audience
            </p>
          </div>
          <div className="mt-3 pt-5">
            <h2>Our solution</h2>
            <ul>
              <li>
                Our team offered a scalable cloud solution with Zuora as the
                billing and subscription system and Salesforce Sales and
                Experience clouds.
              </li>
              <img
                src={CaseStudyPage_5_solution_img1}
                className="img-fluid py-4"
              ></img>
              <li>
                Architecture team developed models and data flows in Zuora and
                Salesforce that included subscriptions, products, product plans,
                product plan charges, billing, payments, etc.
              </li>
              <img
                src={CaseStudyPage_5_solution_img2}
                className="img-fluid py-4"
              ></img>
              <li>
                Our engineering team streamlined the Salesforce {`<->`} Zuora
                integration process and implemented sophisticated logic on the
                Apex side for efficient lead scoring.
              </li>
              <img
                src={CaseStudyPage_5_solution_img3}
                className="img-fluid py-4"
              ></img>
              <li>
                We migrated over 300,000 records from several legacy tables into
                Salesforce and Zuora after an extensive data cleansing process,
                greatly improving data integrity and usability.
              </li>
              <img
                src={CaseStudyPage_5_solution_img4}
                className="img-fluid py-4"
              ></img>
              <li>
                Our technical team provided GDPR and PCI-compliant security,
                including SSO from Google, Salesforce Visibility & Sharings
                configurations, and security event monitoring.
              </li>
              <img
                src={CaseStudyPage_5_solution_img5}
                className="img-fluid pb-4"
              ></img>
            </ul>
          </div>
          <div className="mt-3 py-5">
            <h2>Results </h2>
            <p>
              The system automatically takes the deal to the next stage and
              takes care of typical actions. Managers now to call more, sell
              more, and spend less time on routine tasks. Thanks to the task
              setting function of setting tasks, employees remember all the
              agreements and contact the client on time. The manager sees the
              work of the department in real time and can check what is
              happening with sales at any time.
            </p>
            <p>
              Before the introduction of the CRM-system sales plans were set
              managers in the number of copies sold. Managers tried to sell as
              much goods as possible, offered customers large discounts, special
              promotions. The price of each unit of goods was falling. As a
              result, the company made less profit. Now the approach to sales
              has changed, plans are based on the amount of money.
            </p>
            <p>
              It has become convenient for the manager to see how much money the
              department has earned, whether the goal for the month will be
              fulfilled by the end of the term or not. With the Goals tool in
              the analytics section of Salesforce, it's easy for managers to
              track plan fulfillment. Employees try to get more profit from each
              subscriber, and are more careful about giving discounts to
              clients.
            </p>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}
