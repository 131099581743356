import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./it-infrastructure.scss";
import "./it-infrastructure-animation.scss";
import image from "../../../../assets/images/image - services2.jpg";
import { connect } from "react-redux";
import ContactFormPopup from "../../EasyProject/PricingPage/ContactForm/ContactFormPopup";

function ItInfrastructure({ direction, lang }) {
  const [en] = useState({
    header: "Platform as a service",
    services: [
      {
        name: "Cloud hosting",
        text: "Many software development companies don’t provide hosting services because it requires additional DevOps competence. Our team is not one of them. We are ready to both develop and host an app or website for you! Also, we can can help you with hosting open-source applications like Rocket.chat or Redmine",
      },
      {
        name: "Infrastructure as a service",
        text: "We can service your infrastructure for you including providing application hosting, securing your systems, preforming installations as well as other services",
      },
    ],
    button: "GET STARTED",
  });
  const [ar] = useState({
    header: "خدمات استشارية",
    services: [
      {
        name: "	يمكن أن يساعدك فريق",
        text: " استشارات البنية التحتية لتكنولوجيا المعلومات في تخطيط وإعداد البنية التحتية بالكامل أو أجزائها الفردية",
      },
      {
        name: "	استشارات أمن تكنولوجيا المعلومات",
        text: " يمكننا مساعدتك في تطبيق حلول الأمان للمساعدة في حماية البنية التحتية الخاصة بك وبياناتك",
      },
    ],
    button: "البدء",
  });
  const renderServices = () => {
    if (lang === "EN") {
      return en.services.map((service, index) => (
        <Row key={index}>
          <Col sm={12} lg="12">
            <div className="combined-shape"> </div>
            <h3 className="service-name">{service.name}</h3>
          </Col>
          <Col sm={12} lg="10">
            <p className="service-text">{service.text}</p>
          </Col>
        </Row>
      ));
    } else {
      return ar.services.map((service, index) => (
        <Row key={index}>
          <Col sm={12} lg="12">
            <div className="combined-shape"> </div>
            <h3 className="service-name">{service.name}</h3>
          </Col>
          <Col sm={12} lg="10">
            <p className="service-text">{service.text}</p>
          </Col>
        </Row>
      ));
    }
  };

  return (
    <>
      <div
        className={`services-it-infrastructure`}>
        <div className="page-layout white-page d-flex justify-content-md-center">

          <Container>
            <Row>
              <Col sm={12} lg={9} className="infrastructure-text">
                <Row>
                  <Col xs={12} md={12}>
                    <p className="page-name">SERVICES</p>
                  </Col>
                </Row>
                <Row>
                  <Col md="10">
                    <h1>{lang === "EN" ? en.header : ar.header}</h1>
                  </Col>
                </Row>
                {renderServices()}
                <Row>
                  <Col sm={12} lg="8">
                    <ContactFormPopup label={lang === "EN" ? en.button : ar.button} />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={2}>
                <div className="it-infrastructure-image-container d-flex hide-on-mobile">
                  <img
                      src={image}
                      className="services-services-it-infrastructure-image"
                      alt="RND Proton It Infrastructure"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(ItInfrastructure);
