import React from "react";
import "./caseStudiesPages.scss";
import NavProton from "../../shared/NavBar/NavProton";
import ButtonBack from "../../shared/ButtonBack/ButtonBack";
import Container from "react-bootstrap/Container";
import Footer from "../../Products/Footer/Footer";
import CaseStudyPage_2_img from "../../../../assets/images/CaseStidiesPages/case-study-page-2.png";
import CaseStudyPage_2_solution_img1 from "../../../../assets/images/CaseStidiesPages/case-study-page-2-solution-1.png";
import CaseStudyPage_2_solution_img2 from "../../../../assets/images/CaseStidiesPages/case-study-page-2-solution-2.png";
import CaseStudyPage_2_solution_img3 from "../../../../assets/images/CaseStidiesPages/case-study-page-2-solution-3.png";
import CaseStudyPage_2_solution_img4 from "../../../../assets/images/CaseStidiesPages/case-study-page-2-solution-4.png";
import CaseStudyPage_2_solution_img5 from "../../../../assets/images/CaseStidiesPages/case-study-page-2-solution-5.png";

export default function CaseStudyPage_2() {
  return (
    <>
      <NavProton whiteBackground={true} selected="products" className="mb-5" />
      <ButtonBack redirectPath={"/salesforce"} />
      <div className="case-study-page-layout">
        <Container>
          <div className="row">
            <div className="col-8 header-info">
              <h1>Data orchestration</h1>
              <ul>
                <li>
                  Services offered: Salesforce Sales Cloud, Lightning Web
                  Components, Apex, Visualforce
                </li>
                <li>Location: Spain</li>
                <li>Industry: Insurance</li>
                <li>Team composition: 9</li>
                <li>Time to implement: 1,5 month</li>
              </ul>
            </div>
            <div className="col-4">
              <img src={CaseStudyPage_2_img} className="header-img" />
            </div>
          </div>
          <div className="mt-3 pt-5">
            <h2>Problem</h2>
            <p>
              An insurance company needed to improve their user interface,
              because it was implemented on Salesforce classic and caused some
              troubles with new specialists, because they are got used to
              Lightning. On the other hand they want to improve response time
              from their data processing and enhance the security model of
              transferred data between components. Also in the future they want
              to integrate with external systems that provide custom components
              to simplify some steps of data processing.
            </p>
          </div>
          <div className="mt-3 pt-5">
            <h2>Our solution</h2>
            <ul>
              <li>
                Our team proposed a solution that replaced legacy implementation
                on Classic with Lightning Web Component.
              </li>
              <img
                src={CaseStudyPage_2_solution_img1}
                className="img-fluid py-4"
              ></img>
              <li>
                Our team business analysts team gathers requirements from the
                client and develops an existing business process map, indicating
                high workload problems, and suggests solution for this
                bottleneck.
              </li>
              <img
                src={CaseStudyPage_2_solution_img2}
                className="img-fluid py-4"
              ></img>
              <li>
                Our architecture team analyzes BA’s result and suggests a new
                approach that avoids performance bottleneck and improved
                security model of transferred data. Also the suggested approach
                has the ability to communicate and delegate some of the process
                to other components.
              </li>
              <img
                src={CaseStudyPage_2_solution_img3}
                className="img-fluid py-4"
              ></img>
              <li>
                Our administrator team created necessary metadata in Salesforce
                in order to support flexibility of the new implementation.
              </li>
              <img
                src={CaseStudyPage_2_solution_img4}
                className="img-fluid py-4"
              ></img>
              <li>
                Our technical team provided GDPR and PCI-compliant security,
                including SSO from Google, Salesforce Visibility & Sharings
                configurations, and security event monitoring.
              </li>
              <img
                src={CaseStudyPage_2_solution_img5}
                className="img-fluid pb-4"
              ></img>
            </ul>
          </div>
          <div className="mt-3 py-5">
            <h2>Results </h2>
            <p>
              Successfully implemented the Lightning Web Component system, which
              is completely up-to-date, flexible to changes and new data entry,
              and completely secure. The system is fully automated for the main
              current processes, thereby improving the efficiency of the
              company. Sales managers began to fulfill their KPIs faster, they
              have more time to process leads, and the response time has been
              reduced by 40%.
            </p>
            <p>
              The time from first contact to deal was reduced by almost 3 times.
              In addition, immediately after delivery of the system our company
              trained key users in a few days, which greatly reduced the time
              resource of employees. The implementation of an effective CRM with
              all sales channels allows you to keep all the information and
              display statistics and history in a single system. At all stages
              of work with the client, the project manager communicates
              transparently with the client, manages the team and controls the
              implementation and timing of assigned tasks.
            </p>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}
