import React, { useEffect, useState } from "react";
import NavProton from "../shared/NavBar/NavProton";
import Footer from "./Footer/Footer";
import Tesey from "./Tesey/Tesey";
import Salesforce from "./Salesforce/SalesForce";
import EasyProject from "./EasyProject/EasyProject";
import ReadyToStart from "./ReadyToStart/ReadyToStart";
import "./product.scss";
import PageDivider from "../shared/PageDivider";
import { connect } from "react-redux";

function Products({ lang }) {
  useEffect(() => {
    let p = [].slice.call(document.getElementsByTagName("p"));
    let h1 = [].slice.call(document.getElementsByTagName("h1"));
    let container = document.querySelector(".ready-to-start");
    if (lang === "AR") {
      document.body.style.direction = "rtl";
      h1.forEach((element) => (element.style.textAlign = "right"));
      p.forEach((element) => (element.style.textAlign = "right"));
      container.querySelector(".proton-button").style.width = "300px";
      container.querySelector(".proton-button").style.direction = "ltr";
    } else {
      document.body.style.direction = "ltr";
      h1.forEach((element) => (element.style.textAlign = "left"));
      p.forEach((element) => (element.style.textAlign = "left"));
    }
  });
  return (
    <>
      <div
        className={`proton-products-page`}>
        <NavProton selected="products" whiteBackground={true} />

        <EasyProject />
        <PageDivider direction={"left"}/>
        <Salesforce />
        <ReadyToStart />
        <Footer />
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({ lang: state.lang });
export default connect(mapStateToProps)(Products);
