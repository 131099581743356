import React from "react";
import NavProton from "../shared/NavBar/NavProton";
import Services from "./Services/Services";
import ReadyToStart from "../Products/ReadyToStart/ReadyToStart";
import OurTeam from "./OurTeam/OurTeam";
import ProductExpertise from "./ProductExpertise/ProductExpertise";
import StageOfWork from "./StageOfWork/StageOfWork";
import HowWeCanHelp from "./HowWeCanHelp/HowWeCanHelp";
import CaseStudies from "./CaseStudiesSection/CaseStudies";

import "../../styles/shared.scss";
import "./salesforce.scss";
import Footer from "../Products/Footer/Footer";

export default function Salesforce() {
  return (
    <>
      <div className="salesforce--page">
        <NavProton whiteBackground={true} selected="products" />
        <Services />
        <OurTeam />
        <ProductExpertise />
        <StageOfWork />
        <HowWeCanHelp />
        <CaseStudies />
        <Footer />
      </div>
    </>
  );
}
