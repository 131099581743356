import React from "react";
import Container from "react-bootstrap/Container";

export default function ProductExpertise({ }) {
    return (
        <>
            <div className="base--container page-layout white-page sf-product-expertise">
                <h2>Our Salesforce product expertise</h2>
                <Container>
                    <div className="row mt-5 justify-content-md-center">
                        <div className="sf-product-expertise-cal col-sm-6 col-lg-2 d-flex align-items-center">
                            <p>Sales Cloud</p>
                        </div>
                        <div className="sf-product-expertise-cal col-sm-6 col-lg-2 d-flex align-items-center">
                            <p>Appexchange</p>
                        </div>
                        <div className="sf-product-expertise-cal col-sm-6 col-lg-2 d-flex align-items-center">
                            <p>Service Cloud</p>
                        </div>
                        <div className="sf-product-expertise-cal col-sm-6 col-lg-2 d-flex align-items-center">
                            <p>Marketing Cloud</p>
                        </div>
                        <div className="sf-product-expertise-cal col-sm-6 col-lg-2 d-flex align-items-center">
                            <p>Financial Services Cloud</p>
                        </div>
                    </div>

                    <div className="row justify-content-md-center">
                        <div className="sf-product-expertise-cal col-sm-6 col-lg-2 d-flex align-items-center">
                            <p>Experience Cloud</p>
                        </div>
                        <div className="sf-product-expertise-cal col-sm-6 col-lg-2 d-flex align-items-center">
                            <p>Non-Profit Cloud</p>
                        </div>
                        <div className="sf-product-expertise-cal col-sm-6 col-lg-2 d-flex align-items-center">
                            <p>Commerce Cloud</p>
                        </div>
                        <div className="sf-product-expertise-cal col-sm-6 col-lg-2 d-flex align-items-center">
                            <p>Vlocity</p>
                        </div>
                        <div className="sf-product-expertise-cal col-sm-6 col-lg-2 d-flex align-items-center">
                            <p>Salesforce Lightning architecture</p>
                        </div>
                    </div>

                </Container>
            </div>
        </>
    );
}